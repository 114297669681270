
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import apiDrive from "@/core/services/Drive";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "administration-documents",
    components: {},
    data() {
        return {
            documents: "",
            docType: "",
            document: {
                id: "",
                label: "",
                label_en: "",
                category: "",
                user_types: [],
                order: 0,
                frFile: "",
                enFile: "",
                illustration: "",
            },
            loading: true,
        };
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        setCurrentPageBreadcrumbs(t("pages.driveManagement.documents.title"), []);

        function editDocument(this: any, document) {            
            this.document.id = document.id;
            this.document.label = document.label;
            this.document.label_en = document.label_en;
            this.document.category = document.category.id;
            document.user_types.map((userType) => {
                this.document.user_types.push(userType.id);
            });
            this.document.order = document.order;
            this.document.illustration = document.picto;

            router.push({
                name: "administration-add-documents",
                query: {
                    id: this.document.id,
                    label: this.document.label,
                    label_en: this.document.label_en,
                    category: this.document.category,
                    userTypes: this.document.user_types,
                    order: this.document.order,
                    illustration: this.document.illustration,
                },
            });
        }

        return { t, editDocument };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.driveManagement.documents.title"), []);
        },
    },
    created() {
        apiDrive.getAllDocuments().then((response: any) => {
            this.documents = response.data.documents;
            this.loading = false;
        });
    },
    methods: {
        deleteDoc(id: number) {
            apiDrive.deleteDocument(id).then(() => location.reload());
        },
        getFileType(doc: any) {
            let filetype = doc.filetype;

            switch (doc.filetype) {
                case "application/pdf":
                    filetype = "PDF";
                    break;
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    filetype = "Classeur";
                    break;
                case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                    filetype = "PowerPoint";
                    break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    filetype = "Document";
                    break;
                case "text/plain":
                    filetype = "Texte";
                    break;
                default:
                    filetype = "Image";
                    break;
            }
            return filetype;
        },
    },
});

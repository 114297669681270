<template>
    <div class="mb-5 mb-xl-8 card">
        <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">{{ $t("pages.driveManagement.documents.docsList") }}</span>
            </h3>
            <div class="align-items-end flex-column">
                <router-link v-slot="{ href, navigate }" to="/administration/documents/documents/add">
                    <a class="btn btn-primary btn-light btn-active-light-primary" :href="href" @click="navigate">
                        <span class="menu-title">{{ $t("pages.driveManagement.documents.addDoc") }}</span>
                    </a>
                </router-link>
            </div>
        </div>
        <div class="card-body py-3">
            <div class="table-responsive">
                <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                    <thead>
                        <tr class="fw-bolder text-muted">
                            <th class="min-w-150px">{{ $t("pages.driveManagement.documents.docName") }}</th>
                            <th class="min-w-120px">{{ $t("pages.driveManagement.documents.docType") }}</th>
                            <th class="min-w-120px">{{ $t("pages.driveManagement.documents.docCat") }}</th>
                            <th class="min-w-120px">{{ $t("pages.driveManagement.documents.docTarget") }}</th>
                            <th class="min-w-120px">{{ $t("pages.driveManagement.documents.docOrder") }}</th>
                            <th class="min-w-100px text-end">{{ $t("pages.driveManagement.documents.docActions") }}</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loading">
                        <template v-for="document in documents" v-bind:key="document.id">
                            <tr>
                                <td>
                                    <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{ document.label }}</a>
                                </td>
                                <td>
                                    <span class="badge-success badge text-white">{{ getFileType(document) }}</span>
                                </td>
                                <td>
                                    <span class="text-dark fw-bolder text-hover-primary fs-6">{{ document.category.label }}</span>
                                </td>
                                <td>
                                    <span v-for="userType in document.user_types" v-bind:key="userType.id" class="badge-light badge text-primary me-3">{{ userType.label }}</span>
                                </td>
                                <td>
                                    <span class="badge-light-success badge text-success">{{ document.order }}</span>
                                </td>
                                <td class="text-end">
                                    <a @click="editDocument(document)" href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                        <span class="svg-icon svg-icon-3"
                                            ><svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    opacity="0.3"
                                                    d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                    fill="black"
                                                ></path>
                                                <path
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                    fill="black"
                                                ></path></svg></span></a
                                    ><a @click="deleteDoc(document.id)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                        ><span class="svg-icon svg-icon-3"
                                            ><svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                    fill="black"
                                                ></path>
                                                <path
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    opacity="0.5"
                                                    d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                    fill="black"
                                                ></path>
                                                <path
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    opacity="0.5"
                                                    d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                    fill="black"
                                                ></path></svg></span
                                    ></a>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <div v-if="loading">
                    <div class="d-flex row-auto flex-center w-100 h-300px">
                        <span class="spinner-border text-primary" role="status"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import apiDrive from "@/core/services/Drive";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "administration-documents",
    components: {},
    data() {
        return {
            documents: "",
            docType: "",
            document: {
                id: "",
                label: "",
                label_en: "",
                category: "",
                user_types: [],
                order: 0,
                frFile: "",
                enFile: "",
                illustration: "",
            },
            loading: true,
        };
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        setCurrentPageBreadcrumbs(t("pages.driveManagement.documents.title"), []);

        function editDocument(this: any, document) {            
            this.document.id = document.id;
            this.document.label = document.label;
            this.document.label_en = document.label_en;
            this.document.category = document.category.id;
            document.user_types.map((userType) => {
                this.document.user_types.push(userType.id);
            });
            this.document.order = document.order;
            this.document.illustration = document.picto;

            router.push({
                name: "administration-add-documents",
                query: {
                    id: this.document.id,
                    label: this.document.label,
                    label_en: this.document.label_en,
                    category: this.document.category,
                    userTypes: this.document.user_types,
                    order: this.document.order,
                    illustration: this.document.illustration,
                },
            });
        }

        return { t, editDocument };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.driveManagement.documents.title"), []);
        },
    },
    created() {
        apiDrive.getAllDocuments().then((response: any) => {
            this.documents = response.data.documents;
            this.loading = false;
        });
    },
    methods: {
        deleteDoc(id: number) {
            apiDrive.deleteDocument(id).then(() => location.reload());
        },
        getFileType(doc: any) {
            let filetype = doc.filetype;

            switch (doc.filetype) {
                case "application/pdf":
                    filetype = "PDF";
                    break;
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    filetype = "Classeur";
                    break;
                case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                    filetype = "PowerPoint";
                    break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    filetype = "Document";
                    break;
                case "text/plain":
                    filetype = "Texte";
                    break;
                default:
                    filetype = "Image";
                    break;
            }
            return filetype;
        },
    },
});
</script>
